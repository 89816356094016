<i18n src="./lang.json" />
<template>
  <div>
    <cs-basics-dialog
      :cancelable="false"
      :confirm-function="handleStayActive"
      :confirm-text="$t('stayLoggedIn')"
      confirmable
      dialog-theme="warn"
      :icon="$csBasicsIcons.logout"
      :show-dialog="showDialog"
      :title="$t('sessionExpiringTitle')"
      @close="closeDialog"
    >
      <template #dialogMessageSlot>
        <v-row no-gutters>
          <v-col class="pa-2">
            <p class="csSubHeading dialogHeading">
              {{
                $t('sessionExpiringMessage', {
                  expirationTime: expirationTimeInMin
                })
              }}
            </p>
            <p class="csBoldText countdown">
              {{ $t('timeRemaining') }}: {{ formattedCountdown }}
            </p>
          </v-col>
        </v-row>
      </template>
    </cs-basics-dialog>
    <!-- setting the syncKey activates the propagation of the refresh event across all browser tabs and windows for component instances sharing the same syncKey -->
    <Vidle
      :duration="idleDuration"
      style="height: 0"
      :sync-key="idleSyncKey"
      @idle="handleIdle"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vidle from 'v-idle'

// Users will be logged out after 30 minutes of inactivity, meaning a warning will be displayed after 25 minutes of inactivity, and
// then they have 5 minutes to react
const WAITING_FOR_USER_REACTION_IN_MIN = 5 // time in minutes, time displayed in the dialog
const WAITING_FOR_USER_REACTION = WAITING_FOR_USER_REACTION_IN_MIN * 60 // time in seconds
const IDLE_TIME = 25 * 60 // time in seconds

export default {
  name: 'SessionExpiration',
  components: {
    Vidle
  },
  data() {
    return {
      idleDuration: IDLE_TIME,
      showDialog: false,
      activeTime: undefined,
      countdownTime: undefined,
      timerId: undefined,
      expirationTimeInMin: WAITING_FOR_USER_REACTION_IN_MIN,
      idleSyncKey: window.location.host
    }
  },
  computed: {
    ...mapGetters('csbaseAuthModule', ['user']),
    formattedCountdown() {
      const minutes = Math.floor(this.countdownTime / 60)
      const seconds = this.countdownTime % 60
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.handleStorageEvent)
  },
  mounted() {
    window.addEventListener('storage', this.handleStorageEvent)
    this.activeTime = new Date().getTime()
  },
  methods: {
    handleStorageEvent(event) {
      if (event.key === 'stayActive') {
        this.handleStayActive(false)
      }
    },
    handleIdle() {
      const waitForUserReaction = WAITING_FOR_USER_REACTION
      if (!this.showDialog) {
        this.countdownTime = waitForUserReaction
        this.timerId = setInterval(() => {
          this.countdownTime = this.countdownTime - 1
        }, 1000)
      }
      this.showDialog = true
      setTimeout(() => {
        const now = new Date().getTime()

        if (this.activeTime + this.idleDuration * 1000 < now) {
          // log out user and redirect to the home page in all browsers.
          this.$emit('logout')
          clearInterval(this.timerId)
          this.timerId = undefined
        }
      }, waitForUserReaction * 1000)
    },
    handleStayActive(updateStayActiveInStorage = true) {
      this.showDialog = false
      this.activeTime = new Date().getTime()
      clearInterval(this.timerId)
      this.timerId = undefined
      if (updateStayActiveInStorage) {
        localStorage.setItem('stayActive', this.activeTime)
      }
    },
    closeDialog() {
      this.showDialog = false
    }
  }
}
</script>
